'use client';

import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import type { Locale } from '@fixter/i18n';
import { useResizeViewport } from '$util/hooks/useResizeViewport';
import { VrmLookupDumbModal } from '@/components/molecules/VrmLookupDumbModal';
import { VrmLookupDumb } from '@/components/molecules/VrmLookupDumb';
import type { VrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import type { VrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';

import styles from './index.module.scss';

const stickyButton = (observedTarget: IntersectionObserverEntry, setState: (state: boolean) => void) => {
  const { isIntersecting } = observedTarget;
  if (window.scrollY === 0) {
    setState(false);
  } else {
    setState(!isIntersecting);
  }
};

interface MobileStickyCtaProps {
  readonly locale: Locale;
  readonly sku?: string;
  readonly lookupFormTranslations: VrmLookupFormTranslations;
  readonly modalTranslations: VrmLookupModalTranslations;
}

export default function MobileStickyCta({
  locale,
  sku,
  lookupFormTranslations,
  modalTranslations,
}: MobileStickyCtaProps): ReactNode {
  const [showStickyButton, setShowStickyButton] = useState(false);

  const [stickyButtonIntersectionObserver, setStickyButtonIntersectionObserver] = useState<
    IntersectionObserver | undefined
  >(undefined);

  useEffect(() => {
    const heroVrmTarget = document.querySelector<HTMLElement>('.Hero24VrmForm');
    if (heroVrmTarget) {
      const observer = new IntersectionObserver(
        ([observedTarget]) => stickyButton(observedTarget, setShowStickyButton),
        {
          threshold: [0],
        }
      );

      observer.observe(heroVrmTarget);

      setStickyButtonIntersectionObserver(observer);

      return () => {
        observer.unobserve(heroVrmTarget);
      };
    }
    return () => undefined; // needed due to consistent return lint rule
  }, [setStickyButtonIntersectionObserver, setShowStickyButton]);

  useResizeViewport({
    breakpoint: 'small',
    direction: 'up',
    fn: (isDesktop) => {
      const heroVrmTarget = document.querySelector<HTMLElement>('.Hero24VrmForm');
      if (heroVrmTarget) {
        if (isDesktop) {
          stickyButtonIntersectionObserver?.unobserve(heroVrmTarget);
        } else {
          stickyButtonIntersectionObserver?.observe(heroVrmTarget);
        }
      }
    },
  });

  return (
    showStickyButton &&
    createPortal(
      <div className={styles.stickyCTA}>
        <VrmLookupDumbModal
          className={styles.toggleButton}
          size="M"
          triggeredFrom="MobileStickyCta"
          {...modalTranslations}
        >
          <VrmLookupDumb locale={locale} sku={sku} {...lookupFormTranslations} />
        </VrmLookupDumbModal>
      </div>,
      document.body
    )
  );
}
